import { render, staticRenderFns } from "./WalletsTable.vue?vue&type=template&id=0b0ffe04&scoped=true"
import script from "./WalletsTable.vue?vue&type=script&lang=js"
export * from "./WalletsTable.vue?vue&type=script&lang=js"
import style0 from "./WalletsTable.vue?vue&type=style&index=0&id=0b0ffe04&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b0ffe04",
  null
  
)

export default component.exports